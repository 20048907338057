@import '../../global/styles/lib';

.related-articles {
	&__sep {
		width: 100%;
		height: 2px;
		background: rgba( $red, 0.5 );
		margin: 2rem 0;
	} // &__sep
	
	&__header {
		margin-bottom: 1rem !important;
	} // &__header
	
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		
		&__post {
			width: 100%;
			max-width: 30rem;
			margin-bottom: 1rem;
			$post: &;
			
			&__featured {
				position: relative;
				width: 45%;
				max-width: 12rem;
				height: 8rem;
				outline: 2px solid $transparent;
				transition: outline $hover-transition;
				overflow: hidden;
				img {
					@include absolute-full;
					object-fit: cover;
				} // img

			} // &__featured
			
			&__heading {
				width: 50%;
				font-size: 1.15rem !important;
				margin: 0 !important;
				color: $red;
				transition: color $hover-transition;
			} // &__heading
			
			& > a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				background: $transparent;
				transition: all $hover-transition;
				padding: 1rem;
				&:focus,
				&:hover {
					background: $gray-light;
					
					#{$post}__featured {
						outline-color: $red;
					} // #{$post}__featured
					
				} // &:focus, &:hover
				

				
			} // & > a
			
		} // &__post

		
		@include breakpoint(medium up) {
			justify-content: space-between;
			&__post {
				max-width: 48%;
			} // &__post
			
			&__heading {
				font-size: 1.25rem !important;
			} // &__heading
			
		} // medium up
		
	} // &__list
	
} // .related-articles